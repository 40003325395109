export const FAILED = 'GetCategory/failed';
export const LOADING = 'GetCategory/loading';
export const SUCCESS = 'GetCategory/success';


export const validate = (values) => {
  const errors = {};
  if (!values.question) {
    errors.question = { _error: 'Pertanyaan harus diisi' };
  }
  if (!values.answer) {
    errors.answer = { _error: 'Deskripsi harus diisi' };
  }
  if (!values.category) {
    errors.category = { _error: 'Kategori harus diisi' };
  }
  if (!values.position) {
    errors.position = { _error: 'Posisi harus diisi' };
  }
  return errors;
};

export const dataPositions = [
  { value: '', label: 'Pilih Posisi' },
  { value : 'page_login', label : 'Halaman Login' },
  { value: 'page_main', label: 'Halaman Verifikasi NIK' },
  { value: 'page_info_lpg', label: 'Halaman Pelanggan Info LPG' }
];
